import React, { KeyboardEvent } from 'react';
import { trackEvent } from '../../services';
import Icon, { IconType } from '../common/Icon';

export enum CardType {
    FullWidth,
    Normal,
    Wide
}

interface CardProps {
    image: string;
    linkText: string;
    linkUrl: string;
    title: string;
    descriptions?: string[];
    eventName?: string;
    icon?: IconType;
    overlay?: string;
    type?: CardType;
}

const Card = ({ descriptions, eventName, icon, image, linkText, linkUrl, overlay, title, type }: CardProps) => {
    const handleSelect = () => {
        if (linkUrl !== "/") {
            window.open(linkUrl, '_blank');
        }

        if (eventName) {
            trackEvent(eventName);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleSelect();
        }
    };

    const getCardTypeClass = () => {
        switch (type ?? CardType.Normal) {
            case CardType.FullWidth:
                return 'full-width';
            case CardType.Normal:
                return '';
            case CardType.Wide:
                return 'wide';
        }
    }

    return (
        <div className={`card ${getCardTypeClass()}`} tabIndex={0} onClick={handleSelect} onKeyDown={handleKeyDown}>
            <div className="image-container">
                <img className="image-background" src={image} />
                {overlay &&
                    <img className="image-overlay" src={overlay} />
                }
                {icon &&
                    <Icon type={icon} />
                }
            </div>
            <div className="content">
                <div className="title">
                    {title}
                </div>
                {descriptions && descriptions.map(x =>
                    <div key={x} className="description">
                        {x}
                    </div>
                )}
                <div className="link">
                    {linkText}
                    <Icon type={IconType.Arrowheadright} />
                </div>
            </div>
        </div>
    );
};

export default Card;
