import { TRANSLATIONS } from '../../constants/Translations';
import { useTranslate } from '../../hooks/common';
import { useLocalizedLink } from '../../hooks/common/LocalizedLink';
import ContentContainer from '../common/ContentContainer';
import Icon, { IconType } from '../common/Icon';

const Footer = () => {
    const translate = useTranslate();
    const localizedLink = useLocalizedLink();

    const renderWebsite = () => (
        <div className='footer-links footer-global-website'>
            <a className='footer-link' target='_blank' href={localizedLink(process.env.REACT_APP_INNOMOTICS_URL)}>
                {translate(TRANSLATIONS.footer.innomoticsWebsite)}
            </a>
        </div>
    );

    const renderSocial = () => (
        <div className='footer-social-links'>
            <a className='social-link' href={process.env.REACT_APP_INNOMOTICS_LINKEDIN_URL} title='LinkedIn' target='_blank' rel='noopener'>
                <Icon type={IconType.Linkedin} />
            </a>
        </div>
    );

    const renderInnomotics = () => (
        <div className='footer-links footer-innomotics'>
            <a className='footer-link no-link'>
                {translate(TRANSLATIONS.footer.innomotics).replace('{date}', new Date().getFullYear().toString())}
            </a>
        </div>
    );

    const renderContact = () => (
        <div className='footer-contact'>
            <a className='footer-contact-button' href={localizedLink(process.env.REACT_APP_INNOMOTICS_CONTACT_US_URL)} target='_blank' rel='noopener'>
                {translate(TRANSLATIONS.footer.contactUs)}
                <Icon type={IconType.Email} />
            </a>
        </div>
    );

    const renderLegal = () => (
        <div className='footer-links footer-legal'>
            <a className='footer-link footer-global-website-link' target='_blank' href={localizedLink(process.env.REACT_APP_INNOMOTICS_URL)}>
                {translate(TRANSLATIONS.footer.innomoticsWebsite)}
            </a>
            <a className='footer-link no-link footer-innomotics-link'>
                {translate(TRANSLATIONS.footer.innomotics).replace('{date}', new Date().getFullYear().toString())}
            </a>
            <a className='footer-link' target='_blank' href={localizedLink(process.env.REACT_APP_INNOMOTICS_CORPORATE_INFO_URL)}>
                {translate(TRANSLATIONS.footer.corporateInformation)}
            </a>
            <a className='footer-link' target='_blank' href={localizedLink(process.env.REACT_APP_INNOMOTICS_PRIVACY_URL)}>
                {translate(TRANSLATIONS.footer.privacyNotice)}
            </a>
            <a className='footer-link' target='_blank' href={localizedLink(process.env.REACT_APP_INNOMOTICS_COOKIE_URL)}>
                {translate(TRANSLATIONS.footer.cookieNotice)}
            </a>
            <a className='footer-link' target='_blank' href={localizedLink(process.env.REACT_APP_INNOMOTICS_TERMS_OF_USE_URL)}>
                {translate(TRANSLATIONS.footer.termsOfUse)}
            </a>
            <a className='footer-link' target='_blank' href={localizedLink(process.env.REACT_APP_INNOMOTICS_DIGITAL_ID_URL)}>
                {translate(TRANSLATIONS.footer.digitalId)}
            </a>
        </div>
    );

    return (
        <footer>
            <ContentContainer>
                <div className='footer-container'>
                    {renderWebsite()}
                    {renderSocial()}
                    {renderInnomotics()}
                    {renderContact()}
                    {renderLegal()}
                </div>
            </ContentContainer>
        </footer>
    );
};

export default Footer;
